import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Grid, Typography, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { courses, coursesData } from '../../constants/courses.js';
import { IMAGES } from '../../constants/images';
import { observer } from 'mobx-react';
import { useStores } from 'common-frontend/components/store-provider';
import { EXAM_REGISTRATION, GET_EXAM, GET_SCORE, PASS_EXAM, SEND_PDF, REGISTER_TO_COURSE } from '../../services/api-calls';
import { REMOVE_RELATIONSHIP } from 'common-frontend/services/api-calls';
import { enqueueSnackbar } from 'notistack';
import { generateUniqueKey } from 'common-frontend/utils/common';
import { URLS } from 'common-frontend/constants/env';
import { AcceptPopup } from 'common-frontend/components/accept-popup';
import { LoadingButton } from '@mui/lab';

export const Crafts = observer(() => {
	const { t } = useTranslation();
	const { AuthStore, UserStore, WalletStore, GuidesStore, PopupStore } = useStores();
	const { authProfile } = AuthStore;
	const { user } = UserStore;
	const { dates, getScore, setDate, exam } = WalletStore;
	const { guideStepUpdate } = GuidesStore;
	const { openPopup, closePopup } = PopupStore;

	const intervalRef = useRef(0);

	useEffect(() => {
		if (authProfile && user) {
			console.info('First check exam status');
			GET_EXAM().finally(() => {
				intervalRef.current = setInterval(() => {
					GET_EXAM();
					console.info('Check exam status');
				}, 15 * 1000);
			});
			GET_SCORE('all');
		}
	}, [authProfile, user]);

	useEffect(() => {
		return () => {
			clearInterval(intervalRef.current);
			console.info('Check exam status loop finished');
		};
	}, []);

	return (
		<>
			<div className="crafts-banner"></div>
			<main className="main">
				<Grid container spacing={4}>
					<Grid item lg={12} md={12}>
						<Typography variant="h1" className="mt-32 mb-32">
							{t('crafts.article-1.title')}
						</Typography>
						<Typography className="mt-32 mb-32">{t('crafts.article-1.text')}</Typography>
						<Typography id="article-2" variant="h1">
							{t('crafts.article-2.title')}
						</Typography>
						<Typography className="mt-32 mb-32">
							<Trans i18nKey="crafts.article-2.text" />
						</Typography>
						<Typography id="article-3" variant="h2">
							<Trans i18nKey="crafts.article-3.title" />
						</Typography>
						<Typography className="mt-32 mb-32">
							<Trans i18nKey="crafts.article-3.text" />
						</Typography>
						<div className="courses-container mb-48">
							{courses.map((course) => (
								<Course
									key={course}
									course={course}
									currentCourse={WalletStore.currentCourse}
									getScore={getScore}
									date={dates[course]}
									authProfile={authProfile}
									guideStepUpdate={guideStepUpdate}
									globalSetDate={setDate}
									t={t}
								/>
							))}
						</div>
						<ExamSection intervalRef={intervalRef} t={t} />
					</Grid>
				</Grid>
			</main>
			<FinalPopup exam={exam} openPopup={openPopup} closePopup={closePopup} resetWallet={WalletStore.reset} t={t} />
		</>
	);
});

const Course = ({ course, currentCourse, getScore, date, authProfile, guideStepUpdate, globalSetDate, t }) => {
	const [configureMode, setConfigureMode] = useState(false);
	const [pdfLoading, setPdfLoading] = useState(false);
	const showConfig = configureMode || date;

	const studyingDisabled = course !== currentCourse;

	const handleRegisterClick = (course) => {
		setConfigureMode(!configureMode);
		sessionStorage.setItem(`${course}_register_button_clicked`, 'true');
		guideStepUpdate();
	};

	const handlePdfClick = useCallback((course) => {
		setPdfLoading(true);

		SEND_PDF(course).finally(() => {
			setPdfLoading(false);
		});
	}, []);

	return (
		<div id={course} className="course" key={course}>
			<Typography variant="h3">{t(`courses.${course}.name`)}</Typography>
			<div className="course-image-container">
				<img className="in-article-image course-image" src={IMAGES[course]} alt="crafts" />
			</div>
			<Box className="mt-32 mb-32">
				<Trans i18nKey={`courses.${course}.content`}>
					<Typography></Typography>
					<ul>
						<li>
							<Typography></Typography>
						</li>
					</ul>
				</Trans>
			</Box>
			{/* <div className="spacer"></div> */}
			{(!authProfile || getScore(course) === 0) && (
				<Button
					id={`register-to-${course}`}
					className="course__button"
					variant="contained"
					disabled={studyingDisabled || date}
					onClick={() => {
						handleRegisterClick(course);
					}}
				>
					{t(`courses.${course}.button`)}
				</Button>
			)}
			{showConfig && (
				<CourseConfig course={course} date={date} courseFinished={getScore(course) === 100} globalSetDate={globalSetDate} t={t} />
			)}
			{authProfile && getScore(course) === 100 && (
				<Button
					id={`certificate-button-${course}`}
					className="course__button mt-16"
					disabled={pdfLoading}
					variant="contained"
					size="large"
					onClick={() => {
						handlePdfClick(course);
					}}
				>
					{t('progress.score-success.next-button')}
				</Button>
			)}
		</div>
	);
};

const CourseConfig = ({ course, date: dateParam, courseFinished, globalSetDate, t }) => {
	const [date, setDate] = useState(dateParam);
	const disableNextStep = !date;

	const handleCourseClick = () => {
		globalSetDate(course, date);
		REGISTER_TO_COURSE(course, date);
	};

	return (
		<div className="go-to-course-container mt-16">
			<FormControl id={`dates-radio-${course}`} disabled={dateParam}>
				<FormLabel>{dateParam ? t(`crafts.selected-date`) : t(`courses.${course}.select-dates`)}</FormLabel>
				<RadioGroup
					defaultValue={0}
					onChange={(event) => {
						setDate(event.target.value);
					}}
				>
					{coursesData[course].dates.map((d) => (
						<FormControlLabel key={generateUniqueKey()} value={d} control={<Radio />} label={d} checked={d === date} />
					))}
				</RadioGroup>
			</FormControl>
			{!courseFinished && (
				<Link
					id={`progress-link-${course}`}
					className="router-link progress-link"
					disabled={disableNextStep}
					to={`/user/${course}/progress`}
				>
					<Button disabled={disableNextStep} variant="contained" size="large" onClick={handleCourseClick}>
						{t('crafts.start-course')}
					</Button>
				</Link>
			)}
		</div>
	);
};

const ExamSection = ({ intervalRef, t }) => {
	const { AuthStore, WalletStore, GuidesStore } = useStores();
	const { authProfile } = AuthStore;
	const { exam } = WalletStore;
	const { guideStepUpdate } = GuidesStore;
	const [examRegLoader, setExamRegLoader] = useState(false);

	const examRegHandler = useCallback(() => {
		setExamRegLoader(true);
		EXAM_REGISTRATION().finally(() => {
			sessionStorage.setItem('exam_register_button_clicked', 'true');

			if (sessionStorage.getItem('send_journeyman_certificate_button_pressed')) {
				sessionStorage.removeItem('send_journeyman_certificate_button_pressed');
			}

			guideStepUpdate();

			GET_EXAM().finally(() => {
				setExamRegLoader(false);
			});
		});
	}, [guideStepUpdate]);

	const passExamHandler = useCallback(() => {
		setExamRegLoader(true);
		PASS_EXAM().finally(() => {
			setExamRegLoader(false);

			GET_EXAM().finally(() => {
				setExamRegLoader(false);
			});
		});
	}, []);

	const examValidator = useCallback(() => {
		let result;

		switch (exam?.state) {
			case 'REGISTERED':
				enqueueSnackbar(t('alerts.exam-registered'), {
					variant: 'success',
					autoHideDuration: 5 * 1000,
					preventDuplicate: true,
				});
				result = true;
				break;
			case 'PASSED':
				clearInterval(intervalRef.current);

				if (!sessionStorage.getItem('exam_passed_notification_viewed')) {
					enqueueSnackbar(t('alerts.exam-passed'), {
						variant: 'success',
						autoHideDuration: 5 * 1000,
						preventDuplicate: true,
					});
				}
				result = false;
				break;
			case 'WAITING_FOR_FILE':
				result = false;
				break;
			default:
				result = WalletStore.coursesPassed;
				break;
		}

		if (exam?.state === 'PASSED') {
			sessionStorage.setItem('exam_passed_notification_viewed', 'true');
		} else if (exam?.state) {
			sessionStorage.removeItem('exam_passed_notification_viewed');
		}

		return result;
	}, [exam, WalletStore.coursesPassed, intervalRef, t]);

	return (
		<>
			<Typography id="article-4" variant="h1">
				{t('crafts.article-4.title')}
			</Typography>
			<Typography className="mt-32 mb-16">{t('crafts.article-4.text-examination')}</Typography>
			{exam?.state === 'REGISTERED' || exam?.state === 'PASSED' ? (
				<LoadingButton
					id="pass-exam-button"
					variant="contained"
					size="large"
					onClick={passExamHandler}
					disabled={!authProfile || exam?.state === 'PASSED' || examRegLoader}
					loading={examRegLoader}
				>
					{t('crafts.article-4.button-pass-exam')}
				</LoadingButton>
			) : (
				<LoadingButton
					id="exam-registration-button"
					variant="contained"
					size="large"
					onClick={examRegHandler}
					disabled={!authProfile || !examValidator() || examRegLoader}
					loading={examRegLoader}
				>
					{t('crafts.article-4.button-examination')}
				</LoadingButton>
			)}
		</>
	);
};

const FinalPopup = ({ exam, openPopup, closePopup, resetWallet, t }) => {
	const [finalPopupProps, setFinalPopupProps] = useState({
		id: '',
		isOpen: false,
		title: '',
		confirmText: '',
		onClose: null,
		action: null,
	});

	useEffect(() => {
		if (!sessionStorage.getItem('restart-popup-was-opened') && exam.state === 'PASSED') {
			setFinalPopupProps({
				id: 'final-scenario-popup',
				isOpen: true,
				title: t('progress.final-scenario-popup.title'),
				confirmText: t('progress.final-scenario-popup.confirmText'),
				action: () => {
					openPopup();
					setFinalPopupProps((prev) => ({ ...prev, isOpen: false }));
					REMOVE_RELATIONSHIP().finally(() => {
						resetWallet();
						courses.forEach((course) => {
							sessionStorage.removeItem(`${course}_register_button_clicked`);
						});
						closePopup();
					});
				},
				onClose: () => {
					setFinalPopupProps({
						id: 'restart-scenario-popup',
						isOpen: true,
						title: t('progress.restart-scenario-popup.title'),
						confirmText: t('progress.restart-scenario-popup.confirmText'),
						action: () => {
							sessionStorage.setItem('restart-popup-was-opened', 'true');
							setFinalPopupProps((prev) => ({ ...prev, isOpen: false }));
							window.open(URLS.betaTest, '_blank');
						},
						onClose: () => {
							sessionStorage.setItem('restart-popup-was-opened', 'true');
							setFinalPopupProps((prev) => ({ ...prev, isOpen: false }));
						},
					});
				},
			});
		}
	}, [exam.state, closePopup, openPopup, resetWallet, t]);

	return (
		<AcceptPopup
			id={finalPopupProps.id}
			isOpen={finalPopupProps.isOpen}
			action={finalPopupProps.action}
			onClose={finalPopupProps.onClose}
			title={finalPopupProps.title}
			text={finalPopupProps.confirmText}
		/>
	);
};
